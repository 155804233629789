.custom-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.custom-icon-button:hover {
    background-color: #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-icon-button:focus {
    outline: none;
}

/* Styling for the LinkedInIcon */
.custom-icon-button svg {
    width: 24px;
    height: 24px;
    color: #0077b5; /* You can change the color to match your theme */
}