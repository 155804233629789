.modalContainer{
    position: absolute;
    top: 35%;
    z-index: 999;
    width: 600px;
    left: 30%;
    background-color: yellowgreen;
    padding: 35px 10px;
    border-radius: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    /* FLex */

    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeBtn{
    margin-left: auto;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 44px;
}

.btn{
    border-radius: 5px;
    outline: 0px;
    border: 0px;
    padding: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.btn2{
    background: #f20086;
    border: 1px solid #f20086;
    padding: 5px 10px;
    border-radius: 10px;
    color: whitesmoke;
    cursor: pointer;
}

.radioBtn{
    margin-left: 5px;
}

.manualTrans{
    font-size: 18px;
    color: #f20086;
}
