@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

:root {
    --blue: #5e72e4;
    --indigo: #5603ad;
    --purple: #8965e0;
    --pink: #f3a4b5;
    --red: #f5365c;
    --orange: #fb6340;
    --yellow: #ffd600;
    --green: #2dce89;
    --teal: #11cdef;
    --cyan: #2bffc6;
    --gray: #6c757d;
    --gray-dark: #32325d;
    --light: #ced4da;
    --lighter: #e9ecef;
    --primary: #e14eca;
    --secondary: #f4f5f7;
    --success: #00f2c3;
    --info: #1d8cf8;
    --warning: #ff8d72;
    --danger: #fd5d93;
    --light: #adb5bd;
    --dark: #212529;
    --default: #344675;
    --white: #fff;
    --neutral: #fff;
    --darker: #000;
    --BrightPink: #f36281;
}

.form-control:focus {
    box-shadow: none !important;
    border: 1px solid var(--blue) !important;
}

.login-btn {
    background: #f20086;
    width: 100%;
    outline: none;
    border: none;
    transition: .2s all ease-in-out;
}

.login-btn:hover {
    box-shadow: 3px 3px 10px 3px #dddddd;
}

.form {
    /* width: fit-content; */
    /* margin: auto; */
    padding: 0px;
    background: #27293d;
}
.set-h5-1{
    color: #000;
}
.login-btn:hover{
    background: var(--BrightPink);
}
.login-btn{
    transition: .7s all ease;
}