* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background: #F8F9FB !important;
}

.box-shadow-white {
  -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
  -moz-box-shadow: 3px 3px 10px 3px #dddddd;
  box-shadow: 3px 3px 10px 3px #dddddd;
}

.filter-btn {
  background: #fff;
  outline: none;
  border: 1px solid #eaeaea;
  padding: 4px;
}

.new-img {
  height: 40px;
  width: 40px;
}

:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --gray: #6c757d;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #e14eca;
  --secondary: #f4f5f7;
  --success: #00f2c3;
  --info: #1d8cf8;
  --warning: #ff8d72;
  --danger: #fd5d93;
  --light: #adb5bd;
  --dark: #212529;
  --default: #344675;
  --white: #fff;
  --neutral: #fff;
  --darker: #000;
}

ul li {
  list-style: none;
}

.upper-case {
  text-transform: uppercase;
}

.left-header-link {
  transition: .5s all ease-in-out;
}

.left-header-link:hover {
  transition: .5s all ease-in-out;
  color: var(--primary);
}

.guid-btn {
  font-weight: 400 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #000 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #000 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #000 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #000 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}

.form-select:focus {
  box-shadow: none !important;
  border: 1px solid var(--blue) !important;
}

.select-plus {
  position: absolute;
  top: 10px;
  right: 5px;
}

.cursor {
  cursor: pointer;
}

.chevron {
  transition: all .7s all;
}

.chevron-active {
  /* transform: rotate(180deg); */
  transition: all .7s all;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #fff !important;
  color: #000 !important;
}

.box-shadow {
  /* box-shadow: 0 0 45px 0 rgba(0, 0, 0, .6); */
}

.sidebar {
  overflow: auto;
  border: 1px solid #eaeaea;
  background: #fff;
  /* height: 430px; */
  /* height: 100vh; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #f20086 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #f20086;
  border-radius: 12px;
  border: 4px solid #ffffff;
}

@media only screen and (max-width:1600px) {
  .sidebar {
    border: 1px solid #eaeaea;
    background: #fff;
  }
}

ul.dropdown-menu.show {
  width: 100% !important;
  background: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #1b1b2b !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: #fff !important;
}

.home-left-box {
  padding: 10px 6px;
}

.arrow {
  position: absolute;
  right: 0;
  top: -5px;
}

.new-in-roles-box,
.hiring-box,
.funding-box,
.tech-box {
  background: #f20086;
  color: #fff;
  transition: .7s all ease;
}

.new-in-roles-box:hover,
.hiring-box:hover,
.funding-box:hover,
.tech-box:hover {
  background: var(--blue);
  transition: .7s all ease;
}

.purple-heading {
  color: #f20086;
}

.text-purple {
  color: #f20086;
}

.filter-ul {
  padding: 20px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: transparent !important;
  color: #fff !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  /* box-shadow: 0 0 45px 0 rgba(0, 0, 0, .6) !important; */
  width: 100%;
  overflow-x: auto;
}

.bg-off-white {
  background: #F8F9FB !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1azl6jz-MuiTableCell-root {
  color: #000 !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root {
  color: #000 !important;
}

th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  color: #000 !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-177gid-MuiTableCell-root {
  color: #000 !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  color: #000 !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
  color: #000 !important;
  position: relative !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1ndpvdd-MuiTableCell-root {
  color: #000 !important;
}

.css-1n3h4wo {
  background-color: #1e1e2f !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #fff !important;
}

div#vertical-tabpanel-0 {
  width: 100% !important;
}

div#vertical-tabpanel-1 {
  width: 100% !important;
}

div#vertical-tabpanel-2 {
  width: 100% !important;
}

div#vertical-tabpanel-3 {
  width: 100% !important;
}

div#vertical-tabpanel-4 {
  width: 100% !important;
}

div#vertical-tabpanel-5 {
  width: 100% !important;
}

.img-sales {
  height: 100px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: #fff !important;
}

.setting-tab {
  border: 1px solid #eaeaea;
}

.home-left-box {
  border: 1px solid #eaeaea;
}

.box {
  border: 1px solid #eaeaea;
  padding: 20px 10px;
}

.box ul li {
  list-style: disc !important;
  margin: 0 !important;
  padding: 0 !important;
}

.box ul {
  text-align: start;
  margin-top: 10px !important;

}

.home-right-box {
  border: 1px solid #eaeaea;
}

.new {
  display: inline-block;
  background: #f20086;
  height: 10px;
  width: 10px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  margin-right: 2px;
}

.nav-link {
  position: relative;
}

.w-fit-content {
  width: fit-content !important;
}

.active::before {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 1px;
  transition: .2s all ease-in;
  background: #f20086;
}

.active {
  color: #f20086 !important;
}

.logo-img {
  width: 100%;
  height: 60px;
}

.text-blue {
  color: #5574F7 !important;
}

.fs-12 {
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table {
  color: #000 !important;
  background: #F8F9FB !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table:hover {
  color: #000 !important;
  background: #F8F9FB !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table-thead>tr>td {
  color: #000 !important;
  background: #F8F9FB !important;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-mxhywb {
  display: none !important;
}

tr.ant-table-row.ant-table-row-level-0:hover {
  color: #000 !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>th,
:where(.css-dev-only-do-not-override-mxhywb).ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
  background: #F8F9FB !important;
}

/* Login */
.google-btn {
  background: transparent;
  transition: .3s all ease;
}

.google-btn:hover {
  border: 1px solid #1991eb !important;
  transition: .3s all ease;
}

.box-login-shadow {
  box-shadow: 3px 3px 10px 3px #dddddd;
}

.bg-transparent {
  background: transparent !important;
}

.login-container {
  height: auto;
  max-width: 350px;
}

.border-color-grey {
  color: #d3dae3 !important;
}

.dark-grey {
  color: #5d6a7e !important;
}

.custom-border-left, .custom-border-right, .custom-border-full {
  position: relative;
  width: 100%;
  transition: .4s all ease;
}

.custom-border-left::after, .custom-border-right::after, .custom-border-full::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 1px;
  transition: .2s;
}

.custom-border-left::after {
  background: linear-gradient(to right, #1991eb 50%, grey 50%);
}

.custom-border-right::after {
  background: linear-gradient(to right, grey 50%, #1991eb 50%);
}

.custom-border-full::after {
  background: #1991eb;
}

.fs-14 {
  font-size: 14px !important;
}

.login-bg {
  height: 100vh;
  background: url(http://localhost:3000/static/media/login-bg.8f4f556….svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-bg {
  background: url(./img/right-bg-login.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: inherit;
}

/* .tab-active {
  position: relative;
}

.tab-active::after {
  content: "";
  position: absolute;
  bottom: -12px;
  background: #1991eb;
  width: 100%;
  height: 2px;
  left: 0;
} */

.form-input-paddding {
  padding: 2px 10px !important;
}

body {
  color: #000 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}

.wrapper_container {
  background-color: coral;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.set-button-1{
  display: flex !important;
}
.set-col-div-1 ul{
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  gap: 10px !important;
}
.set-the-div-1{
  text-align: end !important;
  width: 100% !important;
  margin-top: 30px !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 45px !important;
}
/* .form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
} */
.fs-12 {
  font-size: 10px !important;
}
.set-the-input-1{
  margin-bottom: 0 !important;
}
.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3rem + calc(var(--bs-border-width) * 2));
}
.form-floating>label {
  padding: 0.9rem 0.75rem;
}
.set-the-div-1 ul li {
  border: 1px solid;
  border-color: #ff007d;
  color: #000;
  padding: 5px 5px;
}
.set-the-div-1 ul li a{
  color: #000;
  padding: 5px 10px;

}
.sidebar.p-3.rounded {
  padding: 1rem 1rem 0rem 1rem !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 12px) scale(1) !important;
}
.set_link_1{
  text-decoration: none !important;
  color: #d32f2f !important;
  width: 100% !important;
  padding: 5px 15px !important;
}
.set_btn_1{
padding: 0 !important;
}
.set_btn_2 p{
  margin-bottom: 0 !important;
}
.set_btn_2{
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
}
.set_div_1{
  text-align: end;
}
.set_div_1 button{
  background-color: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
      color: #000;
        font-weight: 600;
}
.set_div_2{
  text-align: center;
}
.set_input_1{
  width: 100% !important;
  margin-top: 20px !important;
}
.text-end .mt-3{
  color: #f20086 !important;
  border-color: #f20086 !important;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(242, 0, 134, 0.04) !important;
  border: 1px solid #f20086 !important;
}
.set-div-2{
  background-color: #fff !important;
  border: 1px solid #f20086 !important;
  color: #000 !important;
  transition: all 0.6s ease-in-out !important;
}
.set-div-2:hover{
  background-color: #f20086 !important;
  color: #fff !important;
}
.set-div-2 p{
  font-size: 14px;
  color: #000 !important;
  transition: all 0.6s ease-in-out !important;
}
.set-div-2 h5{
  color: #000 !important;
  transition: all 0.6s ease-in-out !important;
}
.set-div-2:hover h5{
  color: #fff !important;
}
.set-div-2:hover p{
  color: #fff !important;
}
.set-collapse-1{
  color: #ffffff !important;
  background: #f20086 !important;
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  border-bottom-right-radius:0px !important ;
  border-bottom-left-radius:0px !important ;
  font-weight: 600 !important;
  transition: .7s all ease !important;
}
.set-collapse-1:hover{
  /* background: var(--blue) !important; */
  color: #fff !important;
}
.set-table-1 tr{
  padding: 10px !important;
}
.set-table-1 tr th{
  padding: 10px !important;
}
.set-table-1 tr td{
  padding: 10px !important;
  cursor: pointer !important;
}
.MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #ffffff !important;
  transition: .7s all ease !important;
}
.set-collapse-1:hover .MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #ffffff !important;
}

.css-ta84vm-MuiAccordionDetails-root {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid #f20086;
  border-radius: 5px !important;
  border-top-right-radius:0px !important ;
  border-top-left-radius:0px !important ;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
  border: none !important;
}
.set-p-div_1 p{
  margin: 0 !important;
  margin-bottom: 5px !important;
  padding: 0 !important;
}
.active-page {
  background-color: #f20086 !important;
}
.set-the-btn-1{
  color: #f20086;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
}
.set-the-div-2{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 3px;
}
.set-the-div-2 p{
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
.set-the-div-3 p{
  font-style: italic !important;
}
.set-the-div-3 p span{
  border-bottom: 1px solid #000;
}
a.text-dark.text-decoration-none {
  color: #f20086 !important;
}
.set-p-1{
  font-weight: 600;
}
.set-the-div-4 img{
  width: 25%;
}
.set-the-div-4{
  text-align: center;
}