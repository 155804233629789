.set-btn-1 {
    background-color: #f20086;
    border: #f20086;
    transition: .7s all ease;
    padding: 5px 20px !important;
}

.set-btn-1:hover {
    background: var(--blue);
}

.backdrop-wrapper {
    background: lightblue;
}

.input_styles {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: 1px solid grey;
    outline: none;
    padding: 3px 4px;
    border-radius: 5px;
}

.input_btn_styles {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 3px 5px;
    border-radius: 6px;
    outline: none;
    border: 1px solid gray;
    margin-top: 20px;
}